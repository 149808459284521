import React from 'react'
// import { GiNotebook, GiVote } from 'react-icons/gi';
import styled, { keyframes } from 'styled-components'

// import { NavLink } from 'react-router-dom'
import TranslatedText from '../TranslatedText'
import { useLocation } from 'react-router-dom'
import { Box, Button } from '@material-ui/core'
import Devnet from 'components/Header/Devnet'
const iconexchange = require('../../assets/images/entry-icon-swap.svg')
// const iconfarming = require('../../assets/images/entry-icon-farms.svg');
// const iconstake = require('../../assets/images/entry-icon-staking.svg');
// const iconliquidity = require('../../assets/images/icon-liquidity.svg')
const logoDark = require('../../assets/images/whiteTextLogo.gif')
// const freeNft = require('new-module/freeNFT.svg')
// freeNFT.svg
// import LngSwith from '../Header/LngSwith'
// import useHTPrice from '../../hooks/useHtPrice'
// import { useActiveWeb3React } from '../../hooks'
// import TopDecoration from './TopDecoration'
// import BottomDecoration from './BottomDecoration'

interface MobileMenuProps {
  onDismiss: () => void
  visible?: boolean
}

// function isZero(num: number) {
//   return Math.abs(num - 0) <= 1e-8
// }

// eslint-disable-next-line react/prop-types
const MobileMenu: React.FC<MobileMenuProps> = ({ onDismiss, visible }) => {
  // const { pippiPrice } = useHTPrice()
  // const { account } = useActiveWeb3React()
  const location = useLocation()
  if (visible) {
    return (
      <StyledMobileMenuWrapper>
        <StyledBackdrop onClick={onDismiss} />

        <StyledMobileMenu>
          <Bg style={{ background: location.pathname === '/' ? '#2d363e' : '#2d363e' }}></Bg>

          <Cn style={{ position: 'relative', zIndex: '11111' }}>
          <Devnet />
            <img src={logoDark} style={{ height: '75px' }} />
            <Box
              style={{
                backgroundColor: '#0d151f',
                border: '1px solid #ffffff52',
                borderRadius: '26px',
                padding: '2px',
                width: 'fit-content',
                display: 'flex',
                position: 'relative',
                zIndex: 99
              }}
            >
              {/* <Button
                onClick={() => window.open('https://tarality.com/')}
                variant="text"
                style={{ color: '#fff', padding: '8px 10px', borderRadius: '20px' }}
              >
                Exchange
              </Button> */}
              <Button variant="text" style={{ padding: '8px 10px', backgroundColor: '#ffffff', borderRadius: '20px' }}>
                Web3
              </Button>
            </Box>
          </Cn>
          
          <Cn>
            <StyledAbsoluteLink href="#swap">
              <img src={iconexchange} style={{ height: '20px', width: '30px', position: 'relative', top: '4px' }} />
              <TranslatedText translationId={200}>Swap</TranslatedText>
            </StyledAbsoluteLink>
          </Cn>

          {/* <Cn>
            <StyledAbsoluteLink href="#add/E">
              <img src={iconliquidity} style={{ height: '20px', width: '30px', position: 'relative', top: '4px' }} />
              <TranslatedText translationId={198}>Liquidity</TranslatedText>
            </StyledAbsoluteLink>
          </Cn> */}
          {/* <Cn>
            <StyledAbsoluteLink href="#create-token">
              <img src={iconpool} style={{ height: '20px', width: '30px', position: 'relative', top: '4px' }} />

              <span style={{ whiteSpace: 'pre' }}>
                <TranslatedText translationId={200}>Create Token</TranslatedText>
              </span>
            </StyledAbsoluteLink>
          </Cn> */}
          {/* <Cn>
            <StyledAbsoluteLink href="#mint">
              <img
                src="new-module/freeNFT.svg"
                style={{ height: '20px', width: '30px', position: 'relative', top: '4px' }}
              />

              <TranslatedText translationId={200}>Free NFT</TranslatedText>
            </StyledAbsoluteLink>
          </Cn> */}
          {/* <Cn>
            <StyledAbsoluteLink href="#game">
              <BiGame style={{color:"#41c0c4",height:"24px",width:"30px"}} />
              <TranslatedText translationId={200}>Game</TranslatedText>
            </StyledAbsoluteLink>
          </Cn> */}
          {/* <Cn>
            <StyledAbsoluteLink href="https://devnet.taralscan.com/" target="_blank">
              <img
                src="new-module/explorer.svg"
                style={{ height: '20px', width: '30px', position: 'relative', top: '4px' }}
              />

              <TranslatedText translationId={200}>Explorer</TranslatedText>
            </StyledAbsoluteLink>
          </Cn> */}
          {/* <Cn>
            <StyledAbsoluteLink href="https://faucet-devnet.tarality.com/" target="_blank">
              <img
                src="new-module/faucet.svg"
                style={{ height: '20px', width: '30px', position: 'relative', top: '4px' }}
              />

              <TranslatedText translationId={200}>Faucet</TranslatedText>
            </StyledAbsoluteLink>
          </Cn> */}
         
          
          
          {/* <Cn>
            <StyledAbsoluteLink href="#pool">
              <img src={iconpool} style={{ height: '20px', width: '30px', position: 'relative', top: '4px' }} />
              <TranslatedText translationId={198}>Pools</TranslatedText>
            </StyledAbsoluteLink>
          </Cn> */}
        </StyledMobileMenu>
      </StyledMobileMenuWrapper>
    )
  }
  return null
}

// const Bottom = styled.div`
//   position: absolute;
//   left: 0;
//   z-index: 1000;
//   width: 80%;
//   bottom: 120px;
//   right: 0;
//   margin: auto;
//   text-align: center;
//   .mobile-lng-swith {
//     width: 100%;
//     height: 30px;
//     line-height: 30px;
//     margin-bottom: 20px;
//   }
// `

const Bg = styled.div`
  position: absolute;

  top: 0;
  // right: 0;
  // width: 70%;
  // height: 300px;
  left: 0;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(180deg);
  backdrop-filter: blur(23px);
`
const StyledBackdrop = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  // background-color: ${props => props.theme.colors.bg5};
  // background-color:rgb(43 12 69 / 58%);
  // backdrop-filter: blur(3px);
  overflow:hidden;
  z-index: 1111;

`

const StyledMobileMenuWrapper = styled.div`
  position: fixed;
  // background-color: ${props => props.theme.colors.bg5};

  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1111;
`

const slideIn = keyframes`
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(100%);
  }
`

const StyledMobileMenu = styled.div`
  animation: ${slideIn} 0.3s forwards ease-out;
  //background-color: ${props => props.theme.colors.bg1};
  //border-left: 1px solid ${props => props.theme.colors.bg1};
  // background-image: linear-gradient(to bottom, #2d0c47, #260d47, #1e0e47, #150f47, #080f46);
  background: #ffffffa6;
  position: fixed;
  top: 0;
  right: 100%;
  bottom: 0;
  width: 180px;
  padding-top: 20px; 
  box-shadow: 0px 5px 70px rgb(18 15 21);

`

// const StyledLink = styled(NavLink)`
//   position: relative;
//   margin: 2px;
//   padding: 8px;
//   box-sizing: border-box;
//   color: rgb(127, 134, 143);
//   font-size: 16px;
//   font-weight: 700;
//   text-align: left;
//   text-decoration: none;
//   &.active {
//     color: #39d0d8;
//   }

// `
const StyledAbsoluteLink = styled.a`
  position: relative;
  display: block;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  color: #fff !important;
  margin: 2px;
  padding: 8px;
  border-radius: 0.25rem;
  text-decoration: none;
  transition: 1s;
  font-weight: 700;
  text-decoration: none;
  display: flex !important;
  &:hover {
    // background-image: linear-gradient(to bottom, #2d0c47, #260d47, #1e0e47, #150f47, #080f46);
    background: #14131329;
    color: #fff;
  }
  &.active {
    // background-image: linear-gradient(to bottom, #2d0c47, #260d47, #1e0e47, #150f47, #080f46);
    background: #14131329;
    color: #fff;
  }
`
const Cn = styled.div`
  margin: 24px 24px 0 14px;
`
// const Price = styled.div`
//   width: 100%;
//   padding-top: 22px;
//   padding-bottom: 10px;
//   color: ${props => props.theme.colors.primary};
//   text-align: left;
//   font-weight: bolder;
// `
export default MobileMenu
